import React from "react";
import {connect} from 'react-redux'

import { Collapse } from "react-collapse";
import moment from 'moment';
import { toast } from "react-toastify";

import MODAL_ACTIONS from "../../modals/state/modalActions";

import ModuleDisplay from "./vdpModuleDisplay";
import ImageSlider from "../../../common/common-components/imageSlider";
import ExpandFullscreen from "../../../common/common-assets/icons/expandFullscreen";

import VehicleDisplayPageStoreInfo from "./vehicleDisplayPageStoreInfo"
import RecommendedSlider from "../../../common/common-components/recommendedSlider.js";
import {VehicleDisplayPageSideBarModule} from './vehicleDisplayPageSideBar'
import Disclaimer from "./vdpDisclaimer";
import {updateSiteTitle} from '../../marketplaceAppUtils'
import {Helmet} from "react-helmet";

import "./vehicleDisplayPage.scss";


function VehicleDisplayPageInfo(props) {
  const {
    module,
    modules,
    vehicle,
    id,
    vehicleImages,
    selectedModuleIndex,
    setSelectedModuleIndex,
    pushModal,
    mockAdd,
    isOpened,
    setIsOpened,
    saveVehicle,
    user,
    isUserLoggedIn,
    sendModuleViewEts,
    sendGenericActionEts,
    modalList,
    etsInfo,
    moduleDictionary,
    viewTimes,
    externalVdp,
    salespersonInfo,
    storeConfiguration,
  } = props;
  const yearMakeModel = `${vehicle.year} ${vehicle.make} ${vehicle.model}`
  updateSiteTitle(`${yearMakeModel} in ${vehicle.store.city}, ${vehicle.store.state}`)
  let enNumFormatter = Intl.NumberFormat("en-US");
  const initialStyle = isOpened
    ? { height: "auto", overflow: "initial" }
    : { height: "0px", overflow: "hidden" };
  const imageShowHide = isOpened ? "Hide" : "Show";

  const HeadMetaData = () =>{
    let stockType = 'New'
    if (vehicle.stock_type == 'U') {
      if (vehicle.cpo) {
        stockType = "Certified Pre-Owned"
      } else {
        stockType = "Used"
      }
    }
    let title = `${stockType} ${yearMakeModel}`
    let url = location.protocol + '//' + location.host + location.pathname
    let description = `${title} for sale at ${vehicle.store.full_name}`
    if(vehicle.store.city && vehicle.store.city.length && vehicle.store.state && vehicle.store.state.length){
      description += ` in ${vehicle.store.city}, ${vehicle.store.state}`
    }
    return(
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title}/>
        <meta name="description" content={description}/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content={url}/>
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={description}/>
        <meta property="og:image" content={vehicle.thumbnail}/>

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content={url}/>
        <meta property="twitter:title" content={title}/>
        <meta property="twitter:description" content={description}/>
        <meta property="twitter:image" content={vehicle.thumbnail}/>
      </Helmet>
    )
  }

  const StockType = () =>{
      if (vehicle.stock_type == 'N') {
        return(<>New</>)
      } else if (vehicle.stock_type == 'U') {
        if (vehicle.cpo) {
          return(<span>Certified Pre-Owned</span>)
        } else {
          return(<>Used</>)
        }
      }
      else{
        return <React.Fragment/>
      }
  }

  const handleShareURLCopy = () => {
    if (window.location.origin.includes('info')) return window.location.origin + `/${vehicle.vin}`
    if (window.location.pathname.includes('vdp')) return window.location.origin + `/vehicle/v3/vdp/${vehicle.vin}`
    if (window.location.pathname.includes('vehicle')) return window.location.origin + `/vehicle/${vehicle.vin}`
  }

  let phone_number = "";
  if(salespersonInfo){
    if(salespersonInfo.phone){
      phone_number = salespersonInfo.phone;
    }
  }else if (vehicle.store.phone_number) {
    phone_number = vehicle.store.phone_number;
  }

  let impelModule = modules.find(module=> module.module_id === 986)

  let vehicleVersion = '';
  const vehicleInDate = moment(vehicle.in_dt, "YYYY-MM-DD");
  const currentDate = moment().startOf('day');
  vehicleVersion = currentDate.diff(vehicleInDate, 'days', false)

  const enableMap = storeConfiguration ? storeConfiguration.enable_map : true
  const showDealerInfo = storeConfiguration ? storeConfiguration.enable_dealer_info : true
  console.log("dealer info", showDealerInfo)
  const showDealerSection = vehicle.store && showDealerInfo
    && (
        vehicle.store.phone_number || 
        vehicle.store.street || 
        vehicle.store.city ||
        vehicle.store.state ||
        vehicle.store.zip_code ||
        enableMap
      )

  return (
    <div className="vehicle-display-page-vehicle-info-container">
      <div className="vehicle-display-page-vehicle-info">
        <div className="vehicle-display-page-vehicle-info-header">
          <div className="vehicle-display-page-vehicle-info-header-title">
            <div className="vehicle-display-page-vehicle-info-header-title-ymm">
              <HeadMetaData/>
              <h2><StockType/></h2>
              <h1>{yearMakeModel}</h1>
              {vehicle.trim?`${vehicle.trim}`:<React.Fragment/>}
            </div>
            <div className="vehicle-display-page-vehicle-info-header-price-mileage">
              <div className="vehicle-display-page-vehicle-info-header-price">
                {
                  vehicle.price === 0 || !vehicle.price ?
                  `Contact store for price` :
                  `${vehicle.units.currency_unit.symbol}${enNumFormatter.format(vehicle.price)}`
                }
              </div>
              <div className="vehicle-display-page-vehicle-info-header-mileage">
                {enNumFormatter.format(vehicle.mileage) + ` ${vehicle.units.distance_unit.label}`}
              </div>
              {externalVdp === true ?
                <span className="ipacket-version-number">v.{vehicleVersion}</span>
                : null
              }
            </div>
            <div className="vehicle-display-page-vehicle-info-header-vr" />
            <div className="vehicle-display-page-vehicle-info-header-cta">
              <div className="vehicle-display-page-vehicle-info-header-cta-button-group">
                {(storeConfiguration && storeConfiguration.enable_call_button) || !storeConfiguration?
                <a
                  className={"primary-button bordered full-width" + (phone_number === '' ? "hidden" : null )}
                  type="image"
                  alt="Click to call"
                  disabled={etsInfo?true:false}
                  href={`tel:+1${phone_number}`}
                >
                  CALL
                </a>
                :
                <div/>
                }
                {(storeConfiguration && storeConfiguration.enable_confirm_availability) || !storeConfiguration?
                  <button
                    className={"primary-button vehicle-display-page-vehicle-info-header-cta-contact"}
                    type="image"
                    alt="Click to contact dealer"
                    disabled={etsInfo?true:false}
                    onClick={() => {
                      pushModal({
                        type: MODAL_ACTIONS.MODAL_CONSTANTS.ASK_QUESTION,
                        props: {vehicle, id,title:"Ask a question",etsGenericActionSubmitEvent:externalVdp?"ask_question_submit":null},
                      });
                      externalVdp && sendGenericActionEts("ask_question_open")
                    }}
                  >
                    ASK A QUESTION
                  </button>
                  :
                  <React.Fragment/>
                }
                <a
                  className="primary-button bordered full-width"
                  onClick={() => {
                    navigator.clipboard.writeText(handleShareURLCopy())
                    toast.success("Link copied to clipboard")
                  }}
                >
                    SHARE
                </a>
                {/*
                <button
                  className="primary-button bordered full-width"
                  type="image"
                  alt="Click to share this listing"
                  disabled={etsInfo?true:false}
                  onClick={() => {
                    pushModal({
                      type: MODAL_ACTIONS.MODAL_CONSTANTS.SHARE,
                      props: {vehicle,etsGenericActionSubmitEvent:externalVdp?"consumer_share_submit":null},
                    });
                  }}
                >
                  SHARE
                </button>
                */}
              </div>
              {/*Second Row of CTA Buttons*/}
              {/*externalVdp &&
                <div className="vehicle-display-page-vehicle-info-header-cta-button-group">
                  <button
                    className={"primary-button vehicle-display-page-vehicle-info-header-cta-contact"}
                    type="image"
                    alt="Click to contact dealer"
                    onClick={() => {

                    }}
                  >
                    BUY NOW
                  </button>
                  <button
                    className={"primary-button bordered full-width"}
                    type="image"
                    alt="Click to ask a question"
                    onClick={() => {

                    }}
                  >
                    EXAMPLE CTA 1
                  </button>
                  <button
                    className="primary-button bordered full-width"
                    type="image"
                    alt="Click to share this listing"
                    onClick={() => {

                    }}
                  >
                    EXAMPLE CTA 2
                  </button>
                </div>
            */}
            </div>
          </div>
        </div>
        <div className="vehicle-display-page-vehicle-info-body">
          <div className="vehicle-image-toggle">
            <button
              className={
                selectedModuleIndex == 0 ? "hidden" : "secondary-button"
              }
              style={user && user.is_staff === true ? {top: '-15px'} : {top: '0px'}}
              type="image"
              alt="Click to show or hide vehicle images"
              onClick={() => setIsOpened(!isOpened)}
            >
              {imageShowHide} Vehicle Images
            </button>
          </div>
          { impelModule ?
            <Collapse isOpened={isOpened} initialStyle={initialStyle}>
              <div className="vehicle-display-page-impel-view-container">
                <iframe
                  src={impelModule.url}
                  className="vehicle-display-page-impel-view"
                  view="Fit"
                  aria-label={`${module.label}`}
                  allowfullscreen
                />
              </div>
            </Collapse>
            :
            (vehicleImages && vehicleImages.length)?
              <Collapse isOpened={isOpened} initialStyle={initialStyle}>
                <ImageSlider
                  images={vehicleImages}
                  displayingVehicleImages
                  expandable
                  slider
                  mockAdd={mockAdd}
                  vehicle={vehicle}
                  saveVehicle={saveVehicle}
                  isUserLoggedIn={isUserLoggedIn}
                  etsInfo={etsInfo}
                  viewTimes={viewTimes}
                  externalVdp={externalVdp}
                  sendGenericActionEts={sendGenericActionEts}
                  sendModuleViewEts={sendModuleViewEts}
                />
              </Collapse>
            :
              null
          }

          <div className="vehicle-display-page-vehicle-info-module-listing-container">
            <div className="vehicle-display-page-vehicle-info-module-listing-toggle">
              <div
                className="vehicle-display-page-vehicle-info-body-title"
              >
                Documentation
              </div>
            </div>
            <Collapse isOpened={true} initialStyle={initialStyle}>
              <div className="vehicle-display-page-vehicle-info-module-listing">
                {modules &&
                  modules.slice(1).map((module, i) => {
                    return(
                      <VehicleDisplayPageSideBarModule
                        i={i}
                        module={module}
                        setSelectedModuleIndex={setSelectedModuleIndex}
                        selectedModuleIndex={selectedModuleIndex}
                        setIsOpened={setIsOpened}
                        onClick={()=>{
                          pushModal({
                            type: MODAL_ACTIONS.MODAL_CONSTANTS.MODULE_MODAL,
                            props: { module, vehicle, storeConfiguration },
                          });
                        }}
                        etsInfo={etsInfo}
                        moduleDictionary={moduleDictionary}
                        viewTimes={viewTimes}
                        sendGenericActionEts={sendGenericActionEts}
                        sendModuleViewEts={sendModuleViewEts}
                        storeConfiguration={storeConfiguration}
                        externalVdp={externalVdp}
                      />)
                  })}
              </div>
            </Collapse>
          </div>
          <div className="vehicle-display-page-vehicle-info-modules-container">
            <div className="vehicle-display-page-vehicle-info-modules">
              <div
                className={
                  selectedModuleIndex < 1 ? "hidden" : "expand-module-svg"
                }
                onClick={() => {
                  console.log('storeConfiguration display page info', storeConfiguration)
                  pushModal({
                    type: MODAL_ACTIONS.MODAL_CONSTANTS.MODULE_MODAL,
                    props: { module, vehicle , storeConfiguration},
                  });
                }}
              >
                <ExpandFullscreen />
              </div>
              {    console.log("OVERLAY",etsInfo)}
              <ModuleDisplay
                module={module}
                modules={modules}
                vehicle={vehicle}
                pushModal={pushModal}
                mockAdd={mockAdd}
                etsInfo={etsInfo}
                storeConfiguration={storeConfiguration}

              />
            </div>
          </div>
          {props.externalVdp === true ? <Disclaimer disclaimerPosition={2} /> : null}
          {/* recommended vehicles slider here for screen widths <1425px */}
          {/*
            !(externalVdp || etsInfo) ?
            <RecommendedSlider
              title="Similar Vehicles Near Me"
              subheading="We've rounded up cars that could be your perfect match."
              lat={vehicle.store.lat}
              long={vehicle.store.long}
              vin={vehicle.vin}
              make={vehicle.make}
              model={vehicle.model}
              forceSameLocation={true}
              pageLocation="vdp"
            />
            :
            <React.Fragment/>
          */}

          {
            showDealerSection &&
            <div className="vehicle-display-page-vehicle-info-store-container">
              <hr/>
              <div className="vehicle-display-page-vehicle-info-body-title">Dealer Overview</div>
              <VehicleDisplayPageStoreInfo store={vehicle.store} vehicleSite={vehicle.dealer_site_vehicle_link} storeConfiguration={storeConfiguration} />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state =>({
  modalList: state.modal.modalList,
})

export default connect(mapStateToProps) (VehicleDisplayPageInfo);
