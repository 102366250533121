import React from 'react'

let skinPreview = null
let configurationPreview = null
try{
  const urlParams = new URLSearchParams(window.location.search);
  const skinPreviewEncoded = urlParams.get('sp')
  const configurationPreviewEncoded = urlParams.get('cp')
  skinPreview = JSON.parse(atob(decodeURIComponent(skinPreviewEncoded)))
  configurationPreview = JSON.parse(atob(decodeURIComponent(configurationPreviewEncoded)))
}
catch(e){
  console.log(e,'Issue Loading Preview Data')
}


function VehicleDisplayPageSkin(props){
  const{storeSkin,children} = props


  let selectedStoreSkin = skinPreview?{...storeSkin,...skinPreview}:storeSkin
  let skinStyles = {}


  if(selectedStoreSkin.nav_background_color){
    skinStyles.nav_background_color = `
      .marketplace .marketplace-header-nav-container{
        background-color: ${selectedStoreSkin.nav_background_color}
      }
    `
  }

  if(selectedStoreSkin.nav_font_icon_color){
    skinStyles.nav_font_icon_color = `
      .marketplace .marketplace-header-nav-container{
        color: ${selectedStoreSkin.nav_font_icon_color};
        fill: ${selectedStoreSkin.nav_font_icon_color};
      }
    `
  }

  if(selectedStoreSkin.nav_bottom_border_color){
    skinStyles.nav_bottom_border_color = `
      .marketplace .marketplace-header-container .marketplace-header-nav-container{
        border-color: ${selectedStoreSkin.nav_bottom_border_color};
      }
    `
    //THIS IS A TEMPORARY FIX TO ADD SUPPORT FOR PORSCHE FONTS, DO NOT TRY THIS AT HOME
    //PLEASE REMOVE ONCE FONTS ARE OFFICIALLY SUPPORTED
    if(selectedStoreSkin.nav_bottom_border_color.toUpperCase() === '#D5001CFF'){
      skinStyles.font_url =`
        @font-face {
          font-family: 'Custom Dealer Font';
          font-weight: 500;
          font-style: normal;
          src: url('https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-la-regular.min.bdaf41e62559316d30f99df258039d4e.woff2');
        }
        @font-face {
          font-family: 'Custom Dealer Font';
          font-weight: 700;
          font-style: normal;
          src: url('https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-la-bold.min.6b20b9525a53943efc0131a2def13eab.woff2');
        }
        .marketplace, .marketplace h1, .marketplace h2, .marketplace h3, .marketplace h4, .marketplace button, .marketplace input, body, body input {
          font-family: 'Custom Dealer Font';
        }
      `
    }
    //THIS IS A TEMPORARY FIX TO ADD SUPPORT FOR PORSCHE FONTS, DO NOT TRY THIS AT HOME
    //PLEASE REMOVE ONCE FONTS ARE OFFICIALLY SUPPORTED
  }

  if(selectedStoreSkin.nav_accent_bar_background_color){
    skinStyles.nav_accent_bar_background_color = `
      .marketplace .vehicle-display-page-custom-header-container .marketplace-header-cta-container{
        background-color: ${selectedStoreSkin.nav_accent_bar_background_color};
      }
      .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-vehicle-info-container .vehicle-display-page-vehicle-info {
          border-color: ${selectedStoreSkin.nav_accent_bar_background_color};
      }
      .marketplace-header-container .marketplace-header-cta .switcher-dropdown {
        background-color: ${selectedStoreSkin.nav_accent_bar_background_color};
      }
    `
  }

  if(selectedStoreSkin.nav_accent_bar_font_icon_color){
    skinStyles.nav_accent_bar_font_icon_color = `
      .marketplace .vehicle-display-page-custom-header-container .marketplace-header-cta-container{
        color: ${selectedStoreSkin.nav_accent_bar_font_icon_color};
      }
      .marketplace-header-container .marketplace-header-cta .marketplace-header-cta-item svg{
        fill: ${selectedStoreSkin.nav_accent_bar_font_icon_color};
      }
      .marketplace-header-container .marketplace-header-cta .language-name {
        color: ${selectedStoreSkin.nav_accent_bar_font_icon_color};
      }
      .marketplace-header-container .marketplace-header-cta .language-code {
        color: ${selectedStoreSkin.nav_accent_bar_font_icon_color};
      }
      .marketplace-header-container .marketplace-header-cta .switcher-dropdown {
        color: ${selectedStoreSkin.nav_accent_bar_font_icon_color};
      }

    `
  }

  if(selectedStoreSkin.site_background_color){
    skinStyles.site_background_color = `
      .marketplace .marketplace-body{
        background-color: ${selectedStoreSkin.site_background_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_background_color){
    skinStyles.vehicle_background_color = `
      .marketplace .vehicle-display-page-container .vehicle-display-page{
        background-color: ${selectedStoreSkin.vehicle_background_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_section_divider_color){
    skinStyles.vehicle_section_divider_color = `
      .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-sidebar-container .vehicle-display-page-sidebar .vehicle-display-page-sidebar-header{
        border-color: ${selectedStoreSkin.vehicle_section_divider_color};
      }
      .marketplace hr{
        border-color: ${selectedStoreSkin.vehicle_section_divider_color};
      }
      .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-vehicle-info-container .vehicle-display-page-vehicle-info .vehicle-display-page-vehicle-info-header .vehicle-display-page-vehicle-info-header-title .vehicle-display-page-vehicle-info-header-vr{
        border-color: ${selectedStoreSkin.vehicle_section_divider_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_section_title_color){
    skinStyles.vehicle_section_title_color = `
      .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-sidebar-container .vehicle-display-page-sidebar .vehicle-display-page-sidebar-header .vehicle-display-page-sidebar-title{
        color: ${selectedStoreSkin.vehicle_section_title_color};
      }
      .marketplace .vehicle-display-page-vehicle-info-body-title{
        color: ${selectedStoreSkin.vehicle_section_title_color};
      }
      .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-sidebar-container .vehicle-display-page-sidebar .vehicle-display-page-sidebar-title{
        color: ${selectedStoreSkin.vehicle_section_title_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_section_text_color){
    skinStyles.vehicle_section_text_color = `
      .marketplace .vehicle-display-page{
        color: ${selectedStoreSkin.vehicle_section_text_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_cpo_condition_font_color){
    skinStyles.vehicle_cpo_condition_font_color = `
    .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-vehicle-info-container .vehicle-display-page-vehicle-info .vehicle-display-page-vehicle-info-header .vehicle-display-page-vehicle-info-header-title .vehicle-display-page-vehicle-info-header-title-ymm h2 span{
        color: ${selectedStoreSkin.vehicle_cpo_condition_font_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_condition_font_color){
    skinStyles.vehicle_condition_font_color = `
      .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-vehicle-info-container .vehicle-display-page-vehicle-info .vehicle-display-page-vehicle-info-header .vehicle-display-page-vehicle-info-header-title .vehicle-display-page-vehicle-info-header-title-ymm h2{
        color: ${selectedStoreSkin.vehicle_condition_font_color};
      }
    `
  }


  if(selectedStoreSkin.vehicle_ymm_font_color){
    skinStyles.vehicle_ymm_font_color = `
      .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-vehicle-info-container .vehicle-display-page-vehicle-info .vehicle-display-page-vehicle-info-header .vehicle-display-page-vehicle-info-header-title .vehicle-display-page-vehicle-info-header-title-ymm h1{
        color: ${selectedStoreSkin.vehicle_ymm_font_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_trim_font_color){
    skinStyles.vehicle_trim_font_color = `
      .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-vehicle-info-container .vehicle-display-page-vehicle-info .vehicle-display-page-vehicle-info-header .vehicle-display-page-vehicle-info-header-title .vehicle-display-page-vehicle-info-header-title-ymm{
        color: ${selectedStoreSkin.vehicle_trim_font_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_price_font_color){
    skinStyles.vehicle_price_font_color = `
      .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-vehicle-info-container .vehicle-display-page-vehicle-info .vehicle-display-page-vehicle-info-header .vehicle-display-page-vehicle-info-header-title .vehicle-display-page-vehicle-info-header-price-mileage .vehicle-display-page-vehicle-info-header-price{
        color: ${selectedStoreSkin.vehicle_price_font_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_mileage_font_color){
    skinStyles.vehicle_mileage_font_color = `
      .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-vehicle-info-container .vehicle-display-page-vehicle-info .vehicle-display-page-vehicle-info-header .vehicle-display-page-vehicle-info-header-title .vehicle-display-page-vehicle-info-header-price-mileage .vehicle-display-page-vehicle-info-header-mileage{
        color: ${selectedStoreSkin.vehicle_mileage_font_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_images_selected_image_highlight_color){
    skinStyles.vehicle_images_selected_image_highlight_color = `
      .marketplace .slider-container .slider-selector-container .slick-slider .slick-list .slick-track .slick-current{
        border-color: ${selectedStoreSkin.vehicle_images_selected_image_highlight_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_show_images_toggle_font_color){
    skinStyles.vehicle_show_images_toggle_font_color = `
      .marketplace .vehicle-display-page-container .secondary-button{
        color: ${selectedStoreSkin.vehicle_show_images_toggle_font_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_show_images_toggle_background_color){
    skinStyles.vehicle_show_images_toggle_background_color = `
      .marketplace .vehicle-display-page-container .secondary-button{
        background-color: ${selectedStoreSkin.vehicle_show_images_toggle_background_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_show_images_toggle_hover_font_color){
    skinStyles.vehicle_show_images_toggle_hover_font_color = `
      .marketplace .vehicle-display-page-container .secondary-button:hover{
        color: ${selectedStoreSkin.vehicle_show_images_toggle_hover_font_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_show_images_toggle_hover_background_color){
    skinStyles.vehicle_show_images_toggle_hover_background_color = `
      .marketplace .vehicle-display-page-container .secondary-button:hover{
        background-color: ${selectedStoreSkin.vehicle_show_images_toggle_hover_background_color};
      }
    `
  }



  if(selectedStoreSkin.vehicle_cta_primary_button_background_color){
    skinStyles.vehicle_cta_primary_button_background_color = `
      .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-vehicle-info-container .vehicle-display-page-vehicle-info .vehicle-display-page-vehicle-info-header .vehicle-display-page-vehicle-info-header-title .vehicle-display-page-vehicle-info-header-cta .vehicle-display-page-vehicle-info-header-cta-contact{
        background-color: ${selectedStoreSkin.vehicle_cta_primary_button_background_color};
        border-color:${selectedStoreSkin.vehicle_cta_primary_button_background_color};
      }
      .marketplace-modal-container .primary-button, .question-modal form .form-row.button input,.marketplace-modal-container .question-modal form .form-row.button .button{
        background-color: ${selectedStoreSkin.vehicle_cta_primary_button_background_color};
        border-color:${selectedStoreSkin.vehicle_cta_primary_button_background_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_cta_primary_button_font_border_color){
    skinStyles.vehicle_cta_primary_button_font_border_color = `
      .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-vehicle-info-container .vehicle-display-page-vehicle-info .vehicle-display-page-vehicle-info-header .vehicle-display-page-vehicle-info-header-title .vehicle-display-page-vehicle-info-header-cta .vehicle-display-page-vehicle-info-header-cta-contact{
        color: ${selectedStoreSkin.vehicle_cta_primary_button_font_border_color};
      }
      .marketplace-modal-container .primary-button, .question-modal form .form-row.button input,.marketplace-modal-container .question-modal form .form-row.button .button{
        color: ${selectedStoreSkin.vehicle_cta_primary_button_font_border_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_cta_primary_button_hover_background_color){
    skinStyles.vehicle_cta_primary_button_hover_background_color = `
      .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-vehicle-info-container .vehicle-display-page-vehicle-info .vehicle-display-page-vehicle-info-header .vehicle-display-page-vehicle-info-header-title .vehicle-display-page-vehicle-info-header-cta .vehicle-display-page-vehicle-info-header-cta-contact:hover{
        background-color: ${selectedStoreSkin.vehicle_cta_primary_button_hover_background_color};
        border-color: ${selectedStoreSkin.vehicle_cta_primary_button_hover_background_color};
      }
      .marketplace-modal-container .primary-button:hover, .marketplace-modal-container .question-modal form .form-row.button input:hover{
        background-color: ${selectedStoreSkin.vehicle_cta_primary_button_hover_background_color};
        border-color: ${selectedStoreSkin.vehicle_cta_primary_button_hover_background_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_cta_primary_button_hover_font_color){
    skinStyles.vehicle_cta_primary_button_hover_font_color = `
      .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-vehicle-info-container .vehicle-display-page-vehicle-info .vehicle-display-page-vehicle-info-header .vehicle-display-page-vehicle-info-header-title .vehicle-display-page-vehicle-info-header-cta .vehicle-display-page-vehicle-info-header-cta-contact:hover{
        color: ${selectedStoreSkin.vehicle_cta_primary_button_hover_font_color};
      }
      .marketplace-modal-container .primary-button:hover, .marketplace-modal-container .question-modal form .form-row.button input:hover{
        color: ${selectedStoreSkin.vehicle_cta_primary_button_hover_font_color};
      }
    `
  }



  if(selectedStoreSkin.vehicle_cta_secondary_button_background_color){
    skinStyles.vehicle_cta_secondary_button_background_color = `
      .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-vehicle-info-container .vehicle-display-page-vehicle-info .vehicle-display-page-vehicle-info-header .vehicle-display-page-vehicle-info-header-title .vehicle-display-page-vehicle-info-header-cta button{
        background-color: ${selectedStoreSkin.vehicle_cta_secondary_button_background_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_cta_secondary_button_font_border_color){
    skinStyles.vehicle_cta_secondary_button_font_border_color = `
      .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-vehicle-info-container .vehicle-display-page-vehicle-info .vehicle-display-page-vehicle-info-header .vehicle-display-page-vehicle-info-header-title .vehicle-display-page-vehicle-info-header-cta .bordered{
        color: ${selectedStoreSkin.vehicle_cta_secondary_button_font_border_color};
        border-color: ${selectedStoreSkin.vehicle_cta_secondary_button_font_border_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_cta_secondary_button_hover_background_color){
    skinStyles.vehicle_cta_secondary_button_hover_background_color = `
      .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-vehicle-info-container .vehicle-display-page-vehicle-info .vehicle-display-page-vehicle-info-header .vehicle-display-page-vehicle-info-header-title .vehicle-display-page-vehicle-info-header-cta button:hover{
        background-color: ${selectedStoreSkin.vehicle_cta_secondary_button_hover_background_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_cta_secondary_button_hover_font_border_color){
    skinStyles.vehicle_cta_secondary_button_hover_font_border_color = `
      .marketplace .vehicle-display-page-container .vehicle-display-page .vehicle-display-page-vehicle-info-container .vehicle-display-page-vehicle-info .vehicle-display-page-vehicle-info-header .vehicle-display-page-vehicle-info-header-title .vehicle-display-page-vehicle-info-header-cta button:hover{
        color: ${selectedStoreSkin.vehicle_cta_secondary_button_hover_font_border_color};
        border-color: ${selectedStoreSkin.vehicle_cta_secondary_button_hover_font_border_color};
      }
    `
  }





  if(selectedStoreSkin.vehicle_modules_font_icon_color){
    skinStyles.vehicle_modules_font_icon_color = `
      .marketplace .vehicle-display-page-sidebar-module{
        color: ${selectedStoreSkin.vehicle_modules_font_icon_color};
      }
      .marketplace .vehicle-display-page-sidebar-module svg{
        fill: ${selectedStoreSkin.vehicle_modules_font_icon_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_modules_active_background_color){
    skinStyles.vehicle_modules_active_background_color = `
      .marketplace .vehicle-display-page-sidebar-modules .vehicle-display-page-sidebar-module-selected, .marketplace .vehicle-display-page-sidebar-modules .vehicle-display-page-sidebar-module-selected:hover{
        background-color: ${selectedStoreSkin.vehicle_modules_active_background_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_modules_active_font_icon_color){
    skinStyles.vehicle_modules_active_font_icon_color = `
      .marketplace .vehicle-display-page-sidebar-modules .vehicle-display-page-sidebar-module-selected, .marketplace .vehicle-display-page-sidebar-modules .vehicle-display-page-sidebar-module-selected:hover{
        color: ${selectedStoreSkin.vehicle_modules_active_font_icon_color};
      }
      .marketplace .vehicle-display-page-sidebar-modules .vehicle-display-page-sidebar-module-selected svg, .marketplace .vehicle-display-page-sidebar-modules .vehicle-display-page-sidebar-module-selected:hover svg{
        fill: ${selectedStoreSkin.vehicle_modules_active_font_icon_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_modules_hover_background_color){
    skinStyles.vehicle_modules_hover_background_color = `
      .marketplace .vehicle-display-page-sidebar-module:hover{
        background-color: ${selectedStoreSkin.vehicle_modules_hover_background_color};
      }
    `
  }

  if(selectedStoreSkin.vehicle_modules_hover_font_icon_color){
    skinStyles.vehicle_modules_hover_font_icon_color = `
      .marketplace .vehicle-display-page-sidebar-module:hover{
        color: ${selectedStoreSkin.vehicle_modules_hover_font_icon_color};
      }
      .marketplace .vehicle-display-page-sidebar-module:hover svg{
        fill: ${selectedStoreSkin.vehicle_modules_hover_font_icon_color};
      }

    `
  }

  if(selectedStoreSkin.vehicle_dealer_overview_link_color){
    skinStyles.vehicle_dealer_overview_link_color = `
      .marketplace .vehicle-display-page-dealer .vehicle-display-page-dealer-section .vehicle-display-page-dealer-website a{
        color: ${selectedStoreSkin.vehicle_dealer_overview_link_color};
      }
      .marketplace .vehicle-display-page-dealer .vehicle-display-page-dealer-section .vehicle-display-page-dealer-website svg{
        fill: ${selectedStoreSkin.vehicle_dealer_overview_link_color};
      }
      .marketplace .vehicle-display-page-dealer .vehicle-display-page-dealer-section .vehicle-display-page-dealer-contact .vehicle-display-page-dealer-contact-line{
        color: ${selectedStoreSkin.vehicle_dealer_overview_link_color};
      }
      .marketplace .vehicle-display-page-dealer .vehicle-display-page-dealer-section .vehicle-display-page-dealer-contact .vehicle-display-page-dealer-contact-line svg{
        fill: ${selectedStoreSkin.vehicle_dealer_overview_link_color};
      }
      .marketplace-modal-container .share-modal form .form-row .shareable-link{
        color: ${selectedStoreSkin.vehicle_dealer_overview_link_color};
      }
      .marketplace-modal-container .share-modal .message-bottom .message .privacy{
        color: ${selectedStoreSkin.vehicle_dealer_overview_link_color};
      }
      .marketplace-modal-container .question-modal .message-bottom .message .privacy{
        color: ${selectedStoreSkin.vehicle_dealer_overview_link_color};
      }
    `
  }
  if(selectedStoreSkin.vehicle_details_icon_color){
    skinStyles.vehicle_details_icon_color = `
      .marketplace .module-vehicle-details .module-vehicle-details-breakdown .module-vehicle-details-breakdown-item-container .module-vehicle-details-breakdown-item .module-vehicle-details-breakdown-item-image svg{
        fill: ${selectedStoreSkin.vehicle_details_icon_color};
      }
    `
  }


  let skinStyleString = ''
  Object.keys(skinStyles).map(style=>{
    skinStyleString += skinStyles[style]
  })
  return(
    <>
      {children}
      <style>{skinStyleString}</style>
    </>
  )
}
export default VehicleDisplayPageSkin
