import React, { useState, useEffect } from "react";
import LazyLoad from "react-lazy-load";
import "./vehicleDisplayPage.scss";
import FallbackDealerImg from "../../../common/common-assets/images/fallback_dealer_img.png";
import PhoneIcon from "../../../common/common-assets/icons/phoneIcon";
import ModuleArrow from "../../../common/common-assets/icons/moduleArrowIcon";
import NewViewIcon from "../../../common/common-assets/icons/newViewIcon";

import VehicleDisplayPageStoreInfo from "./vehicleDisplayPageStoreInfo"
import VEHICLE_DISPLAY_PAGE_CONSTANTS from "./state/vehicleDisplayPageConstants"
import formatPhoneNumber from "../../../../utils/formatPhoneNumber";
import moment from 'moment';



export function VehicleDisplayPageSideBarModule(props){
  const {module, i, setSelectedModuleIndex,setIsOpened, selectedModuleIndex, onClick,  etsInfo, moduleDictionary, viewTimes,sendGenericActionEts,sendModuleViewEts,storeConfiguration, externalVdp} = props
  const [iconLoadFailed, setIconLoadFailed] = useState(false)
  const isVideoModule = (m) =>{ return(m.content_type=== 'youtube_video' || m.content_type=== 'html5_video' || m.module_id === 986)}
  let iconUrl = !module.module_id||iconLoadFailed? 'https://s3.amazonaws.com/cdn.autoipacket.com/media/marketplace-module-icons/default.svg': module.content_type.includes('video') ? 'https://s3.amazonaws.com/cdn.autoipacket.com/media/marketplace-module-icons/video.svg' :`https://s3.amazonaws.com/cdn.autoipacket.com/media/marketplace-module-icons/${module.module_id}.svg`
  if (module.content_type === "3pa") {
    return (
      <a
        className={`vehicle-display-page-sidebar-module vehicle-display-page-sidebar-module-external-icon ${etsInfo?"vehicle-display-page-sidebar-module-heatmap-view": ""}`}
        key={module.label + "_" + i}
        href={module.url}
        target="_blank"
        onClick={(()=>{
          sendModuleViewEts(module)
        })}
      >
        {
          (etsInfo && viewTimes && module.module_id && etsInfo.module_view_stats[module.module_id+'']) && (
            <div className="vehicle-display-page-sidebar-module-heatmap-container">
              <div className="vehicle-display-page-sidebar-module-heatmap-graph" style={{width: `${(etsInfo.module_view_stats[module.module_id+''].view_seconds/(viewTimes[0].view_seconds>300?300:viewTimes[0].view_seconds))*100}%`}}>
                <div className="vehicle-display-page-sidebar-module-heatmap-time">
                  {etsInfo.module_view_stats[module.module_id+''].view_seconds>300?"5:00+": moment.utc(etsInfo.module_view_stats[module.module_id+''].view_seconds*1000).format('mm:ss')}
                </div>
              </div>
            </div>)
        }
        <img src={iconUrl} onError={()=>{setIconLoadFailed(true)}}/>
        <span>{module.label}</span>
        <NewViewIcon />

      </a>
    );
  }
  // remove CPO Video module from modules section
  if (isVideoModule(module) && ((storeConfiguration && storeConfiguration.enable_vehicle_details_video_section  ) || !externalVdp)) {
    return null;
  }
  return (
    <div
      className={`vehicle-display-page-sidebar-module ${
        (i === selectedModuleIndex && !onClick && !etsInfo)
          ? "vehicle-display-page-sidebar-module-selected"
          : ""
      } ${etsInfo?"vehicle-display-page-sidebar-module-heatmap-view": ""}`}
      key={module.label + "_" + i}
      onClick={() => {
        if(onClick){
          onClick()
        }
        else{
          if (i !== selectedModuleIndex) {
            setSelectedModuleIndex(i);
          }
          if (i ? setIsOpened(false) : setIsOpened(true));
        }
      }}
    >
      {
        (etsInfo && viewTimes && module.module_id && etsInfo.module_view_stats[module.module_id+'']) && (
          <div className="vehicle-display-page-sidebar-module-heatmap-container">
            <div className="vehicle-display-page-sidebar-module-heatmap-time">
              {etsInfo.module_view_stats[module.module_id+''].view_seconds>300?"5:00+": moment.utc(etsInfo.module_view_stats[module.module_id+''].view_seconds*1000).format('mm:ss')}
            </div>
            <div className="vehicle-display-page-sidebar-module-heatmap-graph" style={{width: `${(etsInfo.module_view_stats[module.module_id+''].view_seconds/(viewTimes[0].view_seconds>300?300:viewTimes[0].view_seconds))*100}%`}}>
            </div>
          </div>)
      }

      <img src={iconUrl} onError={()=>{setIconLoadFailed(true)}}/>
      <span>{module.label}</span>
      <ModuleArrow />

    </div>
  );
}

function VehicleDisplayPageSideBar(props) {
  const {
    vehicle,
    module,
    modules,
    loading,
    selectedModuleIndex,
    setSelectedModuleIndex,
    urlHistoryStash,
    history,
    setIsOpened,
    showBackToResult,
    etsInfo,
    moduleDictionary,
    viewTimes,
    sendGenericActionEts,
    sendModuleViewEts,
    storeConfiguration,
    externalVdp
  } = props;
  const [imageLoadFailed, setImageLoadFailed] = useState(false);
  const enableMap = storeConfiguration ? storeConfiguration.enable_map : true
  const showDealerInfo = storeConfiguration ? storeConfiguration.enable_dealer_info : true
  const showDealerSection = vehicle.store && showDealerInfo
    && (
        vehicle.store.phone_number || 
        vehicle.store.street || 
        vehicle.store.city ||
        vehicle.store.state ||
        vehicle.store.zip_code ||
        enableMap
      )
  
  function goToPreviousPage() {
    let previousPath = "/search"
    if(urlHistoryStash.length > 1){
      if(urlHistoryStash[urlHistoryStash.length-2].pathname.includes("/vehicle/")){
        previousPath = "/search"
      } else previousPath = urlHistoryStash[urlHistoryStash.length-2].pathname + urlHistoryStash[urlHistoryStash.length-2].search
    }
    history.push(previousPath)
  }


  return (
    <div className="vehicle-display-page-sidebar-container">
      <div className="vehicle-display-page-sidebar">
        {(() => {
          if (!loading && vehicle && modules) {
            return (
              <React.Fragment>
                <div className="vehicle-display-page-sidebar-header">

                    {showBackToResult?
                      <div className="vehicle-display-page-sidebar-back-container">

                        <span>
                          <ModuleArrow />
                        </span>
                        <span onClick={() => goToPreviousPage()}>
                          Back to Result
                        </span>
                        </div>
                      :
                      <React.Fragment/>}
                  <div className="vehicle-display-page-sidebar-title">
                    Documentation
                  </div>
                </div>
                <div className="vehicle-display-page-sidebar-modules">
                  {modules &&
                    modules.map((module, i) => {
                      return(<VehicleDisplayPageSideBarModule i={i} module={module} setSelectedModuleIndex={setSelectedModuleIndex} selectedModuleIndex={selectedModuleIndex}  setIsOpened={setIsOpened} etsInfo={etsInfo} moduleDictionary={moduleDictionary} viewTimes={viewTimes} sendModuleViewEts={sendModuleViewEts} storeConfiguration={storeConfiguration} externalVdp={externalVdp}/> )
                    })}
                </div>
                <hr />
                {
                  showDealerSection && 
                  <React.Fragment>
                    <div className="vehicle-display-page-sidebar-title">
                      Dealer Overview
                    </div>
                    <VehicleDisplayPageStoreInfo store={vehicle.store} vehicleSite={vehicle.dealer_site_vehicle_link} sendGenericActionEts={sendGenericActionEts} storeConfiguration={storeConfiguration}/>
                  </React.Fragment>
                }
              </React.Fragment>
            );
          }
        })()}
      </div>
    </div>
  );
}

export default VehicleDisplayPageSideBar;
