import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import LazyLoad from 'react-lazy-load'
import VehicleSlideshow from '../../../../common/common-components/vehicleSlideshow'
import TileMiniSlider from '../../../../common/common-components/tileMiniSlider'
import PhoneIcon from '../../../../common/common-assets/icons/phoneIcon'
import IPacketFolderIcon from '../../../../common/common-assets/images/fallback_ipacket_folder.png'
import SavePacketButton from '../../../../common/common-components/savePacketButton'
import formatPhoneNumber from '../../../../../utils/formatPhoneNumber'

import './searchVehicleTile.scss'

function RenderImageHolder({vehicle}) {
  const [useThumbnail, setUseThumbnail] = useState(true)

  function activateSlideshow() {
    var el = document.getElementById("slider-wrapper");
    el.classList.add("slideshow-active");
  }

  if (useThumbnail) {
    return (
      <div className='img-holder'>
        <LazyLoad debounce={false} throttle={125} threshold={200}>
          <img 
            className='mainImage'
            alt={vehicle.make + vehicle.model}
            src={vehicle.thumbnail_url + `?nocache='${Date.now()}'`}
            onError={() => setUseThumbnail(false)}
          />
        </LazyLoad>
        <div className='car-slideshow-wrapper' id='slider-wrapper'>
          <TileMiniSlider vehicle={vehicle} activateSlideshow={activateSlideshow} />
        </div>
      </div>
    )
  }
  return (
    <img 
      className='mainImage-fallback'
      alt='car icon'
      src={IPacketFolderIcon}
    />
  )
  {/* auto-transition on hover vehicle slideshow component below */}
  {/* <div className={`car-slideshow-wrapper ${vehicle.thumbnail_url ? '' : 'adjust-slideshow-position'}`}>
    <VehicleSlideshow vehicle={vehicle} />
  </div> */}
}

function SearchVehicleTile(props){
  const { vehicle, saveVehicle, fillIcon, isUserLoggedIn, externalSrp } = props
  const [currentFillIcon, setCurrentFillIcon] = useState(fillIcon)
  const companyStoreName = vehicle.company_store ? vehicle.company_store.store_full_name : vehicle.company_store_full_name
  const currency_unit = vehicle.currency_unit ? vehicle.currency_unit.symbol : vehicle.units.currency_unit.symbol
  const [likeCount, setLikeCount] = useState("like_count" in vehicle ? vehicle.like_count : 0)
  const [stockType, setStockType] = useState('')
  let enNumFormatter = Intl.NumberFormat('en-US')
  const handleSaveVehicle = (e, vehicle) => {
    e.preventDefault()
    setCurrentFillIcon(!currentFillIcon)
    saveVehicle(vehicle)
    currentFillIcon ? setLikeCount(likeCount - 1) : setLikeCount(likeCount + 1)
  }
  useEffect(() => {
    if (vehicle.stock_type == 'N') {
      setStockType("New")
    } else if (vehicle.stock_type == 'U') {
      if (vehicle.cpo == 'Y') {
        setStockType("Certified Pre-Owned")
      } else {
        setStockType("Used")
      }
    }
  }, [])

  return(
    <NavLink to={`/vehicle${externalSrp?'-ext':''}/${vehicle.vin}`} className="vehicle-tile-navlink-container">
      <div className="search-vehicle-tile-container">
        <div className="search-vehicle-tile"  id='vehicle-tile'>
          <SavePacketButton onClick={(e)=>handleSaveVehicle(e,vehicle)}  count={isUserLoggedIn ? likeCount : null} fillIcon={currentFillIcon}/>
          <div className="search-vehicle-tile-image-container">
            <div className="search-vehicle-tile-image">
              {/* if we ever want the hover slider vehicle slideshow back, just add classname car-img-wrapper-slideshow-active below */}
              <div className="car-img-wrapper"> 
                <RenderImageHolder vehicle={vehicle} />
              </div>
            </div>
          </div>
          <div className="search-vehicle-tile-info-container">
            <div className="search-vehicle-tile-info">
              <h1>{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</h1>
              <p className={vehicle.cpo == 'Y' ? 'stock-type cpo' : 'stock-type'}>{stockType}</p>
              <p>{(vehicle.trim && vehicle.trim.length)?vehicle.trim:'N/A'}</p>
              <p>{enNumFormatter.format(vehicle.miles)} Miles</p>
              <div className="tile-info-financing-price-container">
                {
                  /*
                    <div className="tile-info-financing">
                      <p><span>est. $500/mo</span></p>
                      <p>$500 cash down</p>
                    </div>
                  */
                }
                <div className="tile-info-price">
                  {vehicle.price === 0 ? `Contact store for price` : typeof vehicle.price === 'string' ? vehicle.price :`${currency_unit}${enNumFormatter.format(vehicle.price)}`}
                </div>
              </div>
              <hr/>
              <div className="title-info-company-store-container">
                <p><b>{`${companyStoreName} ${vehicle.distance?`(${vehicle.distance} away)`:""}`}</b></p>
                <p>
                  {vehicle.company_store && vehicle.company_store.store_city ? `${vehicle.company_store.store_city}, ` : ''}
                  {vehicle.company_store && vehicle.company_store.store_state ? vehicle.company_store.store_state : ''}
                </p>
                {
                  vehicle.store_phone_number &&
                  <p><PhoneIcon /><span>{`${formatPhoneNumber(vehicle.store_phone_number)}`}</span></p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  )
}

export default connect(null)(SearchVehicleTile)
