import React, { useState, useRef, useEffect } from "react";
import "./weglotSwitcher.scss";


const languageNamesAndCodes = {
    "en": { name: "english", code: "en" },
    "fr": { name: "french", code: "fr" },
    "es": { name: "spanish", code: "es" },
    "ru": { name: "russian", code: "ru" },
    "pl": { name: "polish", code: "pl" },
    "pt": { name: "portuguese", code: "pt" },
};

function WeglotLanguageSwitcher() {
    const [currentLanguage, setCurrentLanguage] = useState(languageNamesAndCodes["en"]);
    const languageList = Object.values(languageNamesAndCodes);
    const [dropdownOpen, toggleDropdown] = useState(false);
    const switcherRef = useRef(null);

    const languageNameClass = dropdownOpen ? "language-name open" : "language-name closed";
    const languageCodeClass = dropdownOpen ? "language-code closed" : "language-code open";
    const dropdownClass = dropdownOpen ? "switcher-dropdown" : "switcher-dropdown closed";

    const handleLanguageChange = (e, languageCode) => {
        setCurrentLanguage(languageNamesAndCodes[languageCode]);
        sessionStorage.setItem("weglot-current-language", languageCode);
        dispatchEvent(new CustomEvent("languageChange", { detail: { ...languageNamesAndCodes[languageCode] } }));
        e.stopPropagation();
        handleDropdown();
    };

    const handleDropdown = () => {
        dropdownOpen ? toggleDropdown(false) : toggleDropdown(true);
    }

    useEffect(() => {
        const storedLanguage = sessionStorage.getItem("weglot-current-language");
        if (storedLanguage) {
            setCurrentLanguage(languageNamesAndCodes[storedLanguage]);
        }
        document.addEventListener("mousedown", (e) => {
            if (switcherRef.current && !switcherRef.current.contains(e.target)) {
                toggleDropdown(false);
            }
        }, false);
    }, [currentLanguage]);

    return (
        <div className="weglot-switcher" onClick={() => handleDropdown()} ref={switcherRef}>
            <button type="button">
                <label className={languageNameClass} htmlFor="language-name" data-wg-notranslate="manual">{currentLanguage.name}</label>
                <label className={languageCodeClass} htmlFor="language-code" data-wg-notranslate="manual">{currentLanguage.code}</label>
                <input type="hidden" name="language-name" value={currentLanguage.name} />
                <input type="hidden" name="language-code" value={currentLanguage.code} />
            </button>
            <ul 
                className={dropdownClass} 
                aria-hidden={!dropdownOpen}
            >
                {
                    languageList.map((language) => {
                        return (
                            <li 
                                key={language.code} 
                                onClick={(e) => handleLanguageChange(e, language.code)}
                                className={currentLanguage.code === language.code ? "closed" : ""}
                                id={`wg-${language.code}`}
                                data-wg-notranslate="manual" 
                            >{language.name}</li>
                        )
                    })
                }
            </ul>
        </div>
    )
};

export default WeglotLanguageSwitcher;